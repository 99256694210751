<template>
  <div class="relative z-10 text-center flex justify-center flex-col h-full mx-6">
    <h5 class="text-pink-cs text-base">عکس</h5>
    <div class="text-center">
      <p class="text-sm text-white mt-8">عکس کاور ملک رو انتخاب کنید</p>
      <div class="images-profile">
        <Uploader
            v-model="ShowCoverImg"
            :autoUpload="false"
            title=""
            :limit="1"
            @on-change="handleCover"
            class="upload-component"
            :limitPrompt="l => `تعداد آپلود مجاز ${l} می باشد`"
        />
      </div>
    </div>
    <div class="text-center">
      <p class="text-sm text-white mt-8">عکس های ملک رو اینجا آپلود کن</p>
      <div class="images-profile">
        <Uploader
            v-model="ShowImages"
            :autoUpload="false"
            title=""
            :limit="10"
            @on-delete="onDelete"
            @on-change="handleImages"
            class="upload-component"
            :limitPrompt="l => `تعداد آپلود مجاز ${l} می باشد`"
        />
      </div>
    </div>
    <div class="mt-12">
      <a class="btn-custom" @click="next">
        ثبت و ادامه
        <span class="svg-btn-icon2 bg-orange transform rotate-0">
         <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>
        </span>
      </a>
    </div>

  </div>
</template>

<script>
import Uploader from "vux-uploader-component";
import axios from "../../plugins/axios";

export default {
  name: "Step6",
  components: {
    Uploader
  },
  data() {
    return {
      ShowCoverImg: [],
      CoverUrl: null,
      ShowImages: [],
      dataCover: null,
      newDataImagesBase64 : [],
      newDataImages : [],
      dataImages :[],
      imageSend: []
    }
  },
  methods: {
    handleCover() {
      let img = this.ShowCoverImg[0].blob
      let reader = new FileReader();
      reader.onloadend = (file) => {
        this.coverBase64 = reader.result
      };
      reader.readAsDataURL(img);
    },
    handleImages(fileItem){
      let img = fileItem.blob
      let reader = new FileReader();
      reader.onloadend = (file) => {
        this.newDataImagesBase64.push({
          cover : false,
          value : reader.result
        })
      };
      reader.readAsDataURL(img);
    },
    async onDelete(deleteItem, cb) {
      let index = await this.ShowImages.indexOf(deleteItem)
      this.ShowImages.splice(index, 1);
    },
     async next() {
      if (this.ShowCoverImg === null) {
        this.$toast.error('لطفا عکس کاور را انتخاب کنید')
      } else {
        if (this.ShowCoverImg[0].type) {
          this.dataCover = {
            cover: true,
            value: this.coverBase64
          }
        } else {
          this.dataCover = {
            cover: true,
            value: this.CoverUrl
          }
        }
        this.ShowImages.forEach((item , i)=>{
          if (!item.type){
            let link = this.ShowImages[i].url.replace('https://app.bankbongah.com', '')
            this.newDataImages[i] = {
              cover: false,
              value: link
            }
          }
        })
        this.$store.commit('editEstate/SET_IMAGES', [this.dataCover , ...this.newDataImages,...this.newDataImagesBase64])
        await this.$router.push({name : 'EditEstateStep7'})
      }
    }
  },
  mounted() {
    if (this.ShowImages.length === 0) {
      let media = this.$store.state.editEstate.media;

      media.forEach((item, i) => {
        if (i === 0) {
          this.ShowCoverImg.push({
            url: this.$store.state.general.baseURL + item.link
          })
          this.CoverUrl = item.link;
        } else {
          this.ShowImages.push({
            url: this.$store.state.general.baseURL + item.link
          })
        }

      })

    }

  }
}
</script>

<style scoped>
.upload-component {
  padding: unset;
}

.upload-component >>> .vux-uploader_hd {
  display: none !important;
}

.upload-component >>> .vux-uploader_input-box {
  float: right !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 1.5rem;
  border: none;
}

.upload-component >>> .vux-uploader_input-box::after {
  content: '';
  background: url("../../assets/images/icons/shot_image.svg") center center no-repeat;
  width: 100%;
  height: 100%;
}

.upload-component >>> .vux-uploader_input-box::before {
  all: revert;
}

.upload-component >>> .vux-uploader_file {
  float: right !important;
  border-radius: 1.5rem;
}
</style>